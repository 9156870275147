<template>
  <div class="embed-container">
    <div
      v-if="vimeoId"
      id="vimeo"
      :data-vimeo-id="vimeoId"
      data-vimeo-width="100%"
      style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
    />
  </div>
</template>

<script>
import Player from '@vimeo/player'
import CourseServices from './coursesServices'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      percent: 0,
    }
  },
  computed: {
    vimeoId() {
      return this.data.id
    }
  },
  mounted() {
    // watch while load info
    this.$watch('vimeoId', () => {
      if (!this.vimeoId) return

      /**
      retornar as porcentagens e o tempo realmente assistido do video
     */
      const player = new Player('vimeo')

      player.on('progress', this.vimeoPlayProgressEvent)

      player.on('play', () => {
        setInterval(() => {
          console.log(this.percent)
          CourseServices().saveProgress({
            lesson_id: this.data.lesson_id,
            progress: this.percent,
          })
        }, 10000)
      })

      // player.on('ended', '')

      // eslint-disable-next-line no-undef
      unwatch()
    })
  },
  methods: {
    vimeoPlayProgressEvent(data) {
      this.percent = Math.ceil(data.percent * 100)
    },
  }
}
</script>

<style>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
